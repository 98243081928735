import * as React from "react";
import styled, { css } from "styled-components";
import { SubscribeForm } from "../Subscribe";
import { ThemeSwitch } from "../../components";

const IconWrapper = styled.div`
  color: var(--text);

  svg {
    height: 120px;
    width: 120px;
  }
`;

const Catalog = styled.a`
  display: block;
  margin: 60px auto 0px auto;
`;

const H1 = styled.h1`
  font-size: 3.5rem;
  margin: 2rem auto 2.5rem;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Description = styled.p`
  max-width: 700px;
  margin: 0 auto 4rem;
  font-size: 1.4rem;
  line-height: 1.8rem;

  strong {
    text-transform: uppercase;
  }
`;

const HeadingWrapper = styled.div`
  margin: 68px auto 48px;
  min-height: calc(100vh - 120px);
`;

export const Logo = () => (
  <IconWrapper>
    <svg
      width="489"
      height="492"
      viewBox="0 0 489 492"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      alt="a11y examples logo"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M489 246C489 381.862 378.862 492 243 492C209.529 492 177.62 485.316 148.534 473.209C152.665 465.067 155 455.814 155 446C155 433.656 151.305 422.197 144.989 412.724C150.694 403.563 154 392.678 154 381C154 352.854 134.793 329.311 109.094 323.419C111.616 316.78 113 309.556 113 302C113 269.415 87.2564 243 55.5 243C54.7594 243 54.0221 243.014 53.2883 243.043C46.1178 219.989 25.5102 203.094 0.914551 202.051C21.6352 87.159 122.139 0 243 0C378.862 0 489 110.138 489 246ZM385.5 149.5H287.5H198.5H100.5C91.7 149.5 84.5 156.7 84.5 165.5C84.5 174.3 91.7 181.5 100.5 181.5H198.5V303.5V422.5C198.5 432.993 207.007 441.5 217.5 441.5C227.993 441.5 236.5 432.993 236.5 422.5V303.5H249.5V422.5C249.5 432.993 258.007 441.5 268.5 441.5C278.993 441.5 287.5 432.993 287.5 422.5V303.5V181.5H385.5C394.3 181.5 401.5 174.3 401.5 165.5C401.5 156.7 394.3 149.5 385.5 149.5ZM287.5 95C287.5 119.475 267.475 139.5 243 139.5C218.525 139.5 198.5 119.475 198.5 95C198.5 70.525 218.525 50.5 243 50.5C267.475 50.5 287.5 70.525 287.5 95Z"
      />
    </svg>
  </IconWrapper>
);

export const Heading = ({}) => {
  return (
    <HeadingWrapper>
      <Logo />
      <H1>Bitesized web accessibility</H1>
      <Description>
        Get the <strong>free</strong> email course &amp; monthly newsletter for
        Developers and Designers who want to learn accessibility.
      </Description>
      <SubscribeForm />
      <Catalog href="bitesized">Previous articles</Catalog>
    </HeadingWrapper>
  );
};

export default Heading;
