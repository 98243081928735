import * as React from "react";
import styled from "styled-components";
import { SubscribeForm } from "../Subscribe";

const SummaryWrapper = styled.div`
  margin: 0px auto 60px;
  max-width: 600px;
  text-align: left;
`;

const SummaryHeading = styled.h2`
  text-align: left;
  margin-bottom: 2rem;
`;

const SummaryParagraph = styled.p`
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Highlight = styled.em`
  color: var(--text-highlight);
  font-weight: 700;
  font-style: normal;
`;

const List = styled.ul`
  font-size: 1.25rem;
  line-height: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  li {
    margin: 10px auto;
  }
`;

export const CourseSummary = () => {
  return (
    <>
      <SummaryWrapper>
        <SummaryHeading>Why I created this course:</SummaryHeading>
        <SummaryParagraph>
          Hey there!
          <br />
          <br />
          I'm Cormac, a Lead Frontend Engineer who has been building accessible
          web based products for almost a decade.
        </SummaryParagraph>
        <SummaryParagraph>
          Starting out, I struggled to fit accessibility into my day-to-day
          learning. I know first hand how difficult it can be to find the time
          needed to keep up to date with the latest frontend technologies and
          design patterns, and how often accessibility can feel like less of a
          priority.
        </SummaryParagraph>
        <SummaryParagraph>
          However, accessibility is a crucial part of building digital products.
          Accessibility impacts decisions across design, development and even
          content. Despite that it can feel a little intimidating to start.
        </SummaryParagraph>
        <SummaryParagraph>
          After a number of my friends and colleagues asked me for advice about
          how to get started with web accessibility, I realised that the
          resources available aren't the most approachable.
        </SummaryParagraph>
        <SummaryParagraph>
          So, I decided to put together this free course.
        </SummaryParagraph>
        <SummaryParagraph>
          The goal of the "Bitesized Web Accessibility" course is to teach you
          how to deliver accessible digital products in quick and
          easy-to-remember lessons. The course covers a number of topics
          including:
        </SummaryParagraph>
        <List>
          <li>Why accessibility is important</li>
          <li>What are Web Content Accessibility Guidelines (WCAG)</li>
          <li>Accessibility Testing</li>
          <li>How to write accessible components</li>
        </List>
        <SummaryParagraph>
          So, sign up to get access to the free course and newsletter below.
        </SummaryParagraph>
      </SummaryWrapper>
      <SubscribeForm />
    </>
  );
};

export default CourseSummary;
