import * as React from "react";
import { Layout } from "../components/Layout";
import { Heading } from "../components/Heading";
import { CourseSummary } from "../components/CourseSummary";
import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const IndexPage = () => {
  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();

  return (
    <main>
      <SEO
        title={"Bitesized Web Accessibility"}
        titleTemplate={siteTitle}
        description=""
        pathname={siteUrl}
        article={true}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        modifiedDate={new Date(Date.now()).toISOString()}
      />
      <Layout>
        <Heading />
        <CourseSummary />
      </Layout>
    </main>
  );
};

export default IndexPage;
